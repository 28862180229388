<template>
    <div class="mt-12 max-w-lg mx-auto grid gap-8 lg:grid-cols-3 lg:max-w-7xl">
        <div v-for="post in posts" :key="post.blog_title" class="flex flex-col rounded-lg shadow-lg overflow-hidden">
            <div class="flex-shrink-0">
                <img class="h-48 w-full object-cover" :src="post.blog_image" alt="" />
            </div>
            <div class="flex-1 bg-white p-6 py-12 flex flex-col justify-between">
                <div class="flex-1">
<!--                    <p class="text-indigo-700 font-bold uppercase text-sm">-->
<!--                        <a :href="post.category.cat_name" class="hover:underline">-->
<!--                            {{ post.category.cat_name }}-->
<!--                        </a>-->
<!--                    </p>-->
                    <a :href="url('/resources/post/' + post.blog_id + '-' + post.blog_slug)" class="block mt-2">
                        <p class="text-xl font-normal font-title text-gray-800">
                            {{ post.blog_title }}
                        </p>
                        <p class="mt-3 text-lg text-gray-400">
                            {{ post.blog_meta_description }}
                        </p>
                    </a>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-12 max-w-lg mx-auto lg:max-w-7xl" v-if="pagination.loaded">
        <pagination v-model="pagination.page" :records="pagination.total" :per-page="pagination.perPage" @paginate="getPosts" :options="{template: PaginationTheme}"/>
    </div>
</template>

<script>
import {markRaw, ref} from "vue"
import Pagination from 'v-pagination-3'
import PaginationTheme from './Pagination'

export default {
    name: "BlogIndex",
    components: {
        Pagination,
        PaginationTheme: markRaw(PaginationTheme),
    },
    props: {
        category: {
            default: false,
        }
    },
    setup(props) {
        const posts = ref(null)

        const pagination = ref({
            loaded: false,
            page: 1,
            total: 0,
            perPage: 0,
        })

        const getPosts = async () => {
            let url = props.category ?
                base('/resources/category/' + props.category + '?page=' + pagination.value.page) :
                base('/resources/posts?page=' + pagination.value.page)

            await axios.get(url)
                .then((response) => {
                    posts.value = response.data.data

                    pagination.value.loaded = true
                    pagination.value.total = response.data.total
                    pagination.value.perPage = response.data.per_page
                })
                .catch(e => {
                    console.log(e)
                })
        }

        const url = (path) => base(path)

        getPosts()

        return {
            getPosts,
            posts,
            url,
            pagination,
            PaginationTheme,
        }
    }
}
</script>

<style scoped>

</style>
